<template>
  <div class="home-float-btn">
    <div class="hf-btn-sign" @click="signMethod('sign')">
      <IconFontSymbol icon="iconsign_in1" :style="state.iconStyle" />
    </div>
    <div class="hf-btn-invite" @click="signMethod('/inviteShare?from=menu')">
      <img
        class="hfb-invite-img"
        alt="invitation_gift.gif"
        src="./../../assets/invitation_gift.gif"
      />
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import IconFontSymbol from "@/components/IconFontSymbol";
export default {
  name: "HomeFloatBtn",
  props: {
    signMethod: {
      type: Function,
      default: function () {},
    },
  },
  components: {
    IconFontSymbol,
  },
  setup() {
    let iconStyle = { "font-size": "0.28rem" };
    let state = reactive({
      iconStyle,
    });

    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
.home-float-btn {
  position: fixed;
  top: 38.2%;
  right: 3%;
  border-radius: 30px;
  box-shadow: 0px 0px 8px 0px rgba(0, 138, 255, 0.2);

  .hf-btn-sign,
  .hf-btn-invite {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.4rem;
    height: 0.47rem;
  }

  .hf-btn-sign {
    border-radius: 30px 30px 0px 0px;
    background: #fff7ec;
  }

  .hf-btn-invite {
    background: #ffe9e2;
    border-radius: 0px 0px 30px 30px;
  }

  .hfb-invite-img {
    width: 0.28rem;
  }
}
</style>
