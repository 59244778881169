<template>
  <van-swipe :autoplay="3000" lazy-render class="home-banner-swipe">
    <van-swipe-item v-for="item in bannerList" :key="item.id">
      <img :src="item.banner_link" @click="toDetail(item.link)" />
    </van-swipe-item>
  </van-swipe>
</template>
<script>
export default {
  name: "HomeBanner",
  props: {
    bannerList: {
      type: Array,
      default: [],
    },
    toDetail: {
      type: Function,
      default: function () {},
    },
  },
};
</script>
<style lang="scss">
.home-banner-swipe {
  font-size: 0;
  img {
    width: 100%;
    border-radius: 6px;
  }
}
.van-swipe {
  padding-bottom: 15px;
  .van-swipe__indicators {
    bottom: 0px;
    .van-swipe__indicator {
      background: #d8d8d8;
    }
    .van-swipe__indicator--active {
      background-color: #1d6af2;
    }
  }
}
</style>
