<template>
  <van-overlay :show="show" class="signin-overlay-component">
    <div class="so-component-wrapper">
      <div class="soc-wrapper-container">
        <div class="socw-container-title">
          <IconFontSymbol icon="iconleft_decoration" :style="state.iconStyle" />
          <span class="socwc-title-txt"> 签到得云贝 </span>
          <IconFontSymbol
            icon="iconright_decoration"
            :style="state.iconStyle"
          />
        </div>

        <div class="socw-container-info">
          <span class="socwc-info-num">{{
            state.signed_today ? "今日已签到！" : `签到成功！+${bonus}个云贝`
          }}</span>
          <span class="socwc-info-self">
            我的云贝
            <span class="socwci-self-total">{{ total }}</span>
          </span>
        </div>

        <ul class="socw-container-day">
          <li
            :key="item"
            class="socwc-day-li"
            v-for="(item, index) in state.dayList"
          >
            <div
              :class="`socwcd-li-bg ${
                index === state.dayList.length - 1 ? ' socwcd-li-bg--large' : ''
              }`"
            ></div>
            <span
              :class="`socwcd-li-count ${
                index < days
                  ? ' socwcd-li-count--light'
                  : state.specialDay.indexOf(index) !== -1
                  ? ' socwcd-li-count--special'
                  : ''
              }`"
              >{{ item.value }}</span
            >
            <img
              class="socwcd-li-icon"
              :src="index + 1 <= days ? state.signImg : state.notSignImg"
            />
            <span
              :class="`socwcd-li-num ${
                index < days ? ' socwcd-li-count--light' : ''
              }`"
              >{{ item.day }}</span
            >
          </li>
        </ul>
      </div>

      <div class="soc-wrapper-close">
        <van-icon name="close" color="#ffffff" size="30" @click="cancelClick" />
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { reactive } from "vue";

import IconFontSymbol from "@/components/IconFontSymbol";

const iconStyle = { "font-size": "0.15rem" };

export default {
  name: "SigninOverlay",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    signStatus: {
      type: Boolean,
      default: false,
    },
    bonus: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    days: {
      type: Number,
      default: 0,
    },
    cancelClick: {
      type: Function,
      default: function () {},
    },
  },
  components: {
    IconFontSymbol,
  },
  setup() {
    const state = reactive({
      iconStyle,
      specialDay: [2, 4, 6],
      dayList: [
        {
          value: "+5",
          day: "第一天",
        },
        {
          value: "+5",
          day: "第二天",
        },
        {
          value: "+10",
          day: "第三天",
        },
        {
          value: "+5",
          day: "第四天",
        },
        {
          value: "+15",
          day: "第五天",
        },
        {
          value: "+5",
          day: "第六天",
        },
        {
          value: "+25",
          day: "第七天",
        },
      ],
      signImg: require("./../../assets/signed.png"),
      notSignImg: require("./../../assets/not_signed.png"),
    });
    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
.signin-overlay-component {
  z-index: 20 !important;
  display: flex;
  justify-content: center;

  .so-component-wrapper {
    padding-top: 20%;
    // width: 80%;
  }

  .soc-wrapper-container {
    width: 3.18rem;
    height: 2.7rem;
    padding: 0.1rem;
    border-radius: 12px;
    background: #fff;
    // font-size: 0;
    // position: relative;
  }

  .socw-container-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.1rem;

    .socwc-title-txt {
      font-size: 0.18rem;
      margin: 0 0.1rem;
    }
  }

  .socw-container-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.1rem;

    .socwc-info-num {
      font-size: 0.14rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }

    .socwc-info-self {
      font-size: 0.12rem;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.6);
    }

    .socwci-self-total {
      margin-left: 0.04rem;
      font-size: 0.15rem;
      color: #ff8600;
    }
  }

  .socw-container-day {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .socwc-day-li {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      position: relative;
      width: 0.75rem;
      height: 0.93rem;
      margin-right: 0.06rem;
      margin-bottom: 0.06rem;
      border-radius: 8px;
      background: rgba(255, 250, 243, 1);
    }
    .socwc-day-li:nth-child(4n) {
      margin-right: 0;
    }
    .socwc-day-li:last-child {
      width: 1.55rem;
      margin-right: 0;
    }

    .socwcd-li-bg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.62rem;
      height: 0.85rem;
      background: url(./../../assets/signin_bg.png) no-repeat center;
      background-size: cover;
    }

    .socwcd-li-bg--large {
      width: 1.39rem;
      height: 0.86rem;
      background: url(./../../assets/signin_big_bg.png) no-repeat center;
      background-size: cover;
    }

    .socwcd-li-count,
    .socwcd-li-num {
      display: block;
      font-size: 0.12rem;
      color: rgba(0, 0, 0, 0.6);
    }

    .socwcd-li-count--light {
      color: rgba(0, 0, 0, 0.4);
    }

    .socwcd-li-count--special {
      font-weight: 600;
      color: #fd002a;
    }

    .socwcd-li-count {
      margin-top: 0.05rem;
    }

    .socwcd-li-num {
      margin-bottom: 0.05rem;
    }

    .socwcd-li-icon {
      width: 0.28rem;
    }

    .img-day-opacity {
      opacity: 0.4;
    }
  }

  .soc-wrapper-close {
    padding-top: 5px;
    font-size: 0;
    text-align: center;
  }
}
</style>
