<template>
  <div class="home-margin-container" style="font-size: 0">
    <SigninOverlay
      :show="state.show"
      :bonus="state.bonus"
      :total="state.total"
      :days="state.continue_days"
      :cancelClick="cancelClick"
      :signStatus="state.signed_today"
    />
    <div class="home-container">
      <HomeFloatBtn :signMethod="signMethod" />
      <div class="contents-container">
        <div class="swip-container">
          <HomeBanner :bannerList="state.swipe_list" :toDetail="toDetail" />
        </div>

        <div class="contents-module">
          <InviteShareAnalyze title="云分析" />
          <InviteShareCloudtool
            title="云图汇"
            :boxStyle="state.cloudtoolStyle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast, Icon } from "vant";

import homeApi from "@/api/home";
import { swipe_list } from "../tools/config";
import HomeBanner from "@/components/swipes/HomeBanner";
import HomeFloatBtn from "@/components/buttons/HomeFloatBtn";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import SigninOverlay from "@/components/overlays/SigninOverlay";
import InviteShareAnalyze from "@/components/others/InviteShareAnalyze";
import InviteShareCloudtool from "@/components/others/InviteShareCloudtool";

const cloudtoolStyle = "margin-top: 0.05rem";
export default {
  name: "Home",
  components: {
    HomeBanner,
    HomeFloatBtn,
    SigninOverlay,
    InviteShareAnalyze,
    InviteShareCloudtool,
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const state = reactive({
      bonus: 0,
      total: 0,
      text: "",
      show: false,
      cloudtoolStyle,
      videoList: [],
      continue_days: 0,
      signed_today: false,
      swipe_list: swipe_list,
    });

    const router = useRouter();
    const { proxy } = getCurrentInstance();

    // 取消签到弹框
    const cancelClick = () => {
      state.show = false;
    };

    // 去视频详情
    const toDetail = (video_id) => {
      router.push({
        path: "/hotDetail",
        name: "hotDetail",
        query: {
          isHot: true,
          video_id: video_id,
        },
      });
    };

    // get banner list
    const getImageList = async () => {
      const res = await proxy.$request.handleFetchGet(
        homeApi.carmap + "?type=mobile"
      );
      Toast.clear();
      if (res.banner_list) {
        state.swipe_list = res.banner_list || [];
      }
    };

    // 签到、跳转到邀请页
    const signMethod = async (type) => {
      if (type === "sign") {
        const res = await proxy.$request.handleFetchGet(homeApi.signin);
        if (res.code !== 2000) {
          Toast.fail("签到报错了，请稍后再试！");
        }
        state.show = true;
        state.bonus = res.bonus;
        state.total = res.total;
        state.signed_today = res.signed_today;
        state.continue_days = res.continue_days % 7;
      } else {
        router.push(type);
      }
    };

    onMounted(() => {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      getImageList();
    });

    return {
      state,
      toDetail,
      signMethod,
      cancelClick,
    };
  },
};
</script>
